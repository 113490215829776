import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import API_ENDPOINTS from "./global";

interface UserData {
  id: number;
  is_bot?: boolean;
  first_name?: string;
  last_name?: string;
  username?: string;
  language_code?: string;
  is_premium?: boolean;
  photo_url?: string;
  gg?: string;
  phone_number?: string;
}

interface AppContextProps {
  theme: string;
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  fetchPhoneNumber: (id: number) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("Контекст должен быть внутри провайдера");
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

const defaultUserId = 515663523; // удалить в будущем

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState("light");
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const initializeUserData = () => {
      const user = WebApp.initDataUnsafe.user as UserData;

      if (user) {
        setUserData(user);
      } else {
        setUserData({ id: defaultUserId } as UserData);
      }
    };

    initializeUserData();

    const themeParams = window.Telegram?.WebApp.themeParams;
    if (themeParams) {
      const isDarkTheme =
        themeParams.bg_color === "#FFFFFF" ||
        themeParams.text_color === "#FFFFFF";
      setTheme(isDarkTheme ? "dark" : "light");
    }

    window.Telegram?.WebApp.ready();
    WebApp.BackButton.onClick(() => {
      window.history.back();
    });
  }, []);

  const fetchPhoneNumber = async (id: number) => {
    if (!id) {
      console.error("Юзер ID не доступен");
      return;
    }

    try {
      const response = await axios.get(API_ENDPOINTS.GET_PHONE_NUMBER(id));
      setUserData((prevUserData) => {
        if (prevUserData) {
          return {
            ...prevUserData,
            phone_number: response.data.phone_number,
          };
        }
        return prevUserData;
      });
    } catch (error) {
      console.error("Ошибка получения номера:", error);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      fetchPhoneNumber(userData.id);
    }
  }, [userData?.id]);

  return (
    <AppContext.Provider
      value={{ theme, userData, setUserData, fetchPhoneNumber }}
    >
      {children}
    </AppContext.Provider>
  );
};
