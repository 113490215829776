import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { Spin } from "antd";
import "./App.css";
import { useAppContext } from "./AppContext";

const App: React.FC = () => {
  const { theme } = useAppContext();

  return (
    <div className={`App ${theme}`}>
      <Suspense
        fallback={
          <div className="spin-container">
            <Spin size="large" />
          </div>
        }
      >
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
};

export default App;
