
import { lazy, React } from "react";
import { createBrowserRouter } from "react-router-dom";

const AppContainer = lazy(() => import("./components/App/AppContainer"));
const Home = lazy(() => import("./pages/Home/Home"));
const Support = lazy(() => import("./pages/Support/Support"));
const PaymentsPage = lazy(() => import("./components/Finance/Payments"));
const Transaction = lazy(() => import("./pages/Transaction/Transaction"));
const Accounts = lazy(() => import("./pages/Accounts/Accounts"));


export const ROUTES = {
  WELCOME: "/",
  HOME: "/home",
  FINANCE: '/finance',
  SUPPORT: '/support',
  PAYMENTS: '/payments',
  TRANSACTION: '/transaction',
  ACCOUNTS: '/accounts',
};

export const router = createBrowserRouter([
  {
    path: ROUTES.WELCOME,
    element: <AppContainer />,
  },
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.FINANCE,
    element: <Home />,
  },
  {
    path: ROUTES.TRANSACTION,
    element: <Transaction />,
  },
  {
    path: ROUTES.SUPPORT,
    element: <Support />,
  },
  {
    path: ROUTES.PAYMENTS,
    element: <PaymentsPage/>
  },
  {
    path: ROUTES.ACCOUNTS,
    element: <Accounts/>
  }
]);
